window.stringUtils || (window.stringUtils = {});
;(function(stringUtils) {
    const _this = stringUtils;
    const utils = {
        isString: function(string) {
            return typeof string === 'string';
        },
        isArray: function(array) {
            return Array.isArray(array);
        },
        toArray: function(string) {
            return utils.isString(string) && string.split(" ")
        },
        stringsLengthDiff: function(stringA, stringB) {
            const  lengthDiff = (stringA.length - stringB.length);
            return lengthDiff > 0 ? true : false;
        },
        stringsLengthDiffByAbs: function(stringALength, stringBLength) {
            return stringBLength > 0 ? Math.abs(stringALength - stringBLength) : 0;
        },
        arrayLast: function(array) {
            return array[array.length - 1];
        },
        stringTemplate: function(str, character) {
            return str.split(character);
        },
        stringTemplateLength: function(str, character) {
            return this.stringTemplate(str, character).length - 1;
        }
    };

    _this.trimStart = function(string) {
        return utils.isString(string) && string.replace(/^\s+/, '');
    };
    _this.trimEnd = function(string) {
        return utils.isString(string) && string.replace(/\s+$/, '')
    };
    _this.trimAll = function(string) {
        return utils.isString(string) && string.trim().replace(/\s\s+/g, '');
    };
    _this.toString = function(array) {
        return utils.isArray(array) && array.join(" ");
    };
    _this.isEmpty = function(string) {
        return (!string || string.length === 0 );
    };
    _this.divideToTwoParts = function(config) {

        const string = config.string,
              type = config.type,
              range = config.range,
              limitAtStart = config.limitAtStart || 10,
              limitAfterSimulate = config.limitAfterSimulate || 5;

        if (!utils.isString(string)) return false;

        let toArray = utils.toArray(string),
            half = Math.ceil(toArray.length / 2),
            firstHalf = toArray.splice(0, half),
            lastHalf = toArray.splice(-half);

        let firstHalfAsString = _this.toString(firstHalf),
            firstHalfLength = firstHalfAsString.length,
            lastHalfAsString = _this.toString(lastHalf),
            lastHalfLength = lastHalfAsString.length;

        if ( utils.stringsLengthDiffByAbs(firstHalfLength, lastHalfLength) > limitAtStart ) {

            let simulateFirstStr = firstHalfAsString.concat(" ", lastHalf[0]).length,
                simulateLastStr = utils.arrayLast(firstHalf).concat(" ", lastHalfAsString).length;

            let diff = utils.stringsLengthDiffByAbs(simulateFirstStr, simulateLastStr)

            if ( utils.stringsLengthDiff(firstHalfAsString, lastHalfAsString) && diff > limitAfterSimulate) {
                do {
                    lastHalf.unshift(utils.arrayLast(firstHalf));
                    firstHalf.pop();
                } while (firstHalf.length > 1 &&
                         utils.arrayLast(firstHalf).length < limitAfterSimulate &&
                         utils.stringsLengthDiffByAbs(firstHalfLength, lastHalfLength) < limitAfterSimulate);
            } else if ( diff > limitAfterSimulate ) {
                do {
                    firstHalf.push(lastHalf[0])
                    lastHalf.shift();
                } while (lastHalf.length > 1 &&
                         lastHalf[0].length < limitAfterSimulate &&
                         utils.stringsLengthDiffByAbs(firstHalfLength, lastHalfLength) < limitAfterSimulate);
            }
        }

        if (utils.isString(type) && type === 'conjunctions') {
            let lastElementFromFirstHalf = utils.arrayLast(firstHalf),
                conjunctionsMaxLength = Number.isInteger(range) ? range : 3;
            if (lastElementFromFirstHalf && lastElementFromFirstHalf.length <= conjunctionsMaxLength) {
                firstHalf.pop();
                lastHalf.unshift(lastElementFromFirstHalf);
            }
        }

        return {
            firstHalf: _this.toString(firstHalf),
            lastHalf: _this.toString(lastHalf)
        };
    };
    _this.stringFormat = function(str) {
        const varSign = "%s",
              varsLength = arguments.length - 1,
              varsInFormatLength = utils.stringTemplateLength(str, varSign),
              stringTemplate = utils.stringTemplate(str, varSign);

        if ( varsLength !== varsInFormatLength ) {
            throw new Error(_this.stringFormat(
                'Wrong number of variables passed. Used %s, required %s',
                varsLength.toString(),
                varsInFormatLength.toString())
            );
        }

        let resultingString = '',
            idx = 0;
        for (idx; idx <= varsLength; idx++) {
            let isArgumentsDefined = arguments[idx + 1] || '';
            resultingString += stringTemplate[idx] + isArgumentsDefined;
        }
        return resultingString;
    };
    _this.objectLength = function(obj) {
        let resultObjectLength = Object.keys(obj).length;
        return resultObjectLength;
    }
})(stringUtils)
